// MyIcon.js
import React from "react";

export const IconMetaSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#61D5A9"
      />
      <circle
        cx="9.55366"
        cy="8.99165"
        r="5.61982"
        fill="url(#paint0_linear_443_3757)"
      />
      <circle
        cx="9.55375"
        cy="10.1158"
        r="3.93387"
        fill="url(#paint1_linear_443_3757)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3757"
          x1="9.55366"
          y1="3.37183"
          x2="9.55366"
          y2="14.6115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3757"
          x1="9.55375"
          y1="6.18188"
          x2="9.55375"
          y2="14.0496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconSearchAds = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17.9548 9.01363C17.8324 12.7307 19.0935 17.9689 13.645 17.8585C11.4657 17.9812 9.27402 18.0303 7.0824 17.9812C3.82556 17.9444 -0.165898 18.0793 0.00551389 13.6753C0.00551389 10.4735 0.00551389 7.27164 0.00551389 4.06981C-0.10468 2.08247 1.45027 0.266876 3.45825 0.193271C5.87026 0.0337931 8.29453 -0.0398121 10.7065 0.0215256C18.7384 -0.174755 18.0038 2.13154 17.9548 9.01363Z"
        fill="#57C099"
      />
      <circle
        cx="9.27266"
        cy="8.72734"
        r="5.45455"
        transform="rotate(-90 9.27266 8.72734)"
        fill="url(#paint0_linear_443_3789)"
      />
      <ellipse
        cx="9.27264"
        cy="8.72724"
        rx="3.81818"
        ry="3.81818"
        transform="rotate(90 9.27264 8.72724)"
        fill="url(#paint1_linear_443_3789)"
      />
      <circle
        cx="9.27264"
        cy="8.72724"
        r="2.18182"
        transform="rotate(-90 9.27264 8.72724)"
        fill="url(#paint2_linear_443_3789)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3789"
          x1="9.27266"
          y1="3.27279"
          x2="9.27266"
          y2="14.1819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3789"
          x1="9.27264"
          y1="4.90906"
          x2="9.27264"
          y2="12.5454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_443_3789"
          x1="9.27264"
          y1="6.54542"
          x2="9.27264"
          y2="10.9091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconSocialAds = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#ABEBD1"
      />
      <ellipse
        cx="4.5"
        cy="10.5"
        rx="1.5"
        ry="1.5"
        transform="rotate(-90 4.5 10.5)"
        fill="url(#paint0_linear_443_3777)"
      />
      <ellipse
        cx="8"
        cy="10"
        rx="2"
        ry="2"
        transform="rotate(-90 8 10)"
        fill="url(#paint1_linear_443_3777)"
      />
      <circle
        cx="12.5"
        cy="9.5"
        r="2.5"
        transform="rotate(-90 12.5 9.5)"
        fill="url(#paint2_linear_443_3777)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3777"
          x1="4.5"
          y1="9"
          x2="4.5"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3777"
          x1="8"
          y1="8"
          x2="8"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_443_3777"
          x1="12.5"
          y1="7"
          x2="12.5"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconPMax = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#469D7C"
      />
      <circle
        cx="6.5"
        cy="11.5"
        r="2.5"
        transform="rotate(-90 6.5 11.5)"
        fill="url(#paint0_linear_443_3785)"
      />
      <ellipse
        cx="9.81829"
        cy="8.81818"
        rx="3.81818"
        ry="3.81818"
        transform="rotate(90 9.81829 8.81818)"
        fill="url(#paint1_linear_443_3785)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3785"
          x1="6.5"
          y1="9"
          x2="6.5"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3785"
          x1="9.81829"
          y1="5"
          x2="9.81829"
          y2="12.6364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconOtaAds = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#CEFFEB"
      />
      <circle
        cx="9.55366"
        cy="8.99165"
        r="5.61982"
        fill="url(#paint0_linear_443_3782)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3782"
          x1="9.55366"
          y1="3.37183"
          x2="9.55366"
          y2="14.6115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#61D5A9" />
          <stop offset="1" stop-color="#61D5A9" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconSeo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#FEBDB6"
      />
      <path
        d="M14 4.00069C13.9725 4.00023 13.9449 4 13.9173 4C11.2016 4 9 6.23858 9 9C9 11.7614 11.2016 14 13.9173 14C13.9449 14 13.9725 13.9998 14 13.9993L14 4.00069Z"
        fill="url(#paint0_linear_796_4302)"
      />
      <path
        d="M4 13.9993C4.0275 13.9998 4.05506 14 4.08266 14C6.79842 14 9 11.7614 9 9C9 6.23858 6.79842 4 4.08266 4C4.05507 4 4.0275 4.00023 4 4.00069L4 13.9993Z"
        fill="url(#paint1_linear_796_4302)"
      />
      <path
        d="M4.00069 4C4.00023 4.0275 4 4.05505 4 4.08266C4 6.79843 6.23858 9 9 9C11.7614 9 14 6.79843 14 4.08266C14 4.05505 13.9998 4.0275 13.9993 4L4.00069 4Z"
        fill="url(#paint2_linear_796_4302)"
      />
      <path
        d="M13.9993 14C13.9998 13.9725 14 13.9449 14 13.9173C14 11.2016 11.7614 9 9 9C6.23858 9 4 11.2016 4 13.9173C4 13.9449 4.00023 13.9725 4.00069 14L13.9993 14Z"
        fill="url(#paint3_linear_796_4302)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_796_4302"
          x1="9"
          y1="9"
          x2="18.8347"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.515547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_796_4302"
          x1="9"
          y1="9"
          x2="-0.834687"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.450547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_796_4302"
          x1="8.99991"
          y1="9"
          x2="8.99991"
          y2="-0.834686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.500547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_796_4302"
          x1="9.00009"
          y1="9"
          x2="9.00009"
          y2="18.8347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.520547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconCms = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#ED8E95"
      />
      <path
        d="M3 9L15 9C14.6864 12.0901 12.1198 14.5 9 14.5C5.88023 14.5 3.31361 12.0901 3 9Z"
        fill="url(#paint0_linear_443_3763)"
      />
      <circle cx="9" cy="8" r="5" fill="url(#paint1_linear_443_3763)" />
      <defs>
        <linearGradient
          id="paint0_linear_443_3763"
          x1="13.6401"
          y1="13.4525"
          x2="5.52075"
          y2="-4.3839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.420547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3763"
          x1="5.39343"
          y1="4.00369"
          x2="9.84197"
          y2="11.1214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFECE7" />
          <stop offset="0.780547" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconRateMatch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#CACDFE"
      />
      <circle cx="8" cy="9" r="5" fill="url(#paint0_linear_443_3768)" />
      <ellipse
        cx="13"
        cy="9"
        rx="3"
        ry="3"
        transform="rotate(-180 13 9)"
        fill="url(#paint1_linear_443_3768)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_443_3768"
          x1="8"
          y1="4"
          x2="8"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EEFF" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3768"
          x1="11.8"
          y1="7.2"
          x2="16"
          y2="11.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0551884" stop-color="#F1EEFF" />
          <stop offset="0.84117" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconPushTech = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.4989 9.28675C18.3727 13.1164 19.672 18.5134 14.0585 18.3996C11.8131 18.526 9.55503 18.5766 7.297 18.526C3.94148 18.4881 -0.170925 18.6271 0.00568097 14.0897C0.00568097 10.7908 0.00568097 7.49197 0.00568097 4.19313C-0.107852 2.14557 1.49422 0.274963 3.56304 0.199127C6.04814 0.0348171 8.54585 -0.0410184 11.031 0.0221778C19.3062 -0.18005 18.5493 2.19613 18.4989 9.28675Z"
        fill="#ECA7E4"
      />
      <circle
        cx="9.55366"
        cy="8.99165"
        r="5.61982"
        fill="url(#paint0_linear_443_3772)"
      />
      <circle
        cx="9.55375"
        cy="10.1156"
        r="3.93387"
        fill="url(#paint1_linear_443_3772)"
      />
      <circle cx="9.5" cy="11.5" r="2.5" fill="url(#paint2_linear_443_3772)" />
      <defs>
        <linearGradient
          id="paint0_linear_443_3772"
          x1="9.55366"
          y1="3.37183"
          x2="9.55366"
          y2="14.6115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_443_3772"
          x1="9.55375"
          y1="6.18176"
          x2="9.55375"
          y2="14.0495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_443_3772"
          x1="9.5"
          y1="9"
          x2="9.5"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEFFEB" />
          <stop offset="1" stop-color="#CEFFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const DecreaseIcon = ({ size = 9 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
      >
        <g clip-path="url(#clip0_1578_7318)">
          <path
            d="M7.25409 6.1006C7.21579 6.05928 7.16223 6.03547 7.1059 6.03473H5.73437V2.72935C5.73437 2.60592 5.70693 2.46875 5.59723 2.46875H3.40277C3.26361 2.48505 3.15188 2.59119 3.12847 2.72935V6.03473H1.8941C1.78048 6.03623 1.6896 6.12953 1.6911 6.24315C1.69183 6.29948 1.71564 6.35304 1.75696 6.39134L4.36286 8.9424C4.43707 9.01813 4.55865 9.01939 4.63438 8.94518C4.63533 8.94426 4.63625 8.94334 4.63717 8.9424L7.24306 6.39134C7.32639 6.31411 7.33132 6.18395 7.25409 6.1006Z"
            fill="#E70000"
          />
          <path
            d="M3.40277 2.05722H5.59722C5.71084 2.05722 5.80296 1.96511 5.80296 1.85149C5.80296 1.73787 5.71084 1.64575 5.59722 1.64575H3.40277C3.28915 1.64575 3.19704 1.73787 3.19704 1.85149C3.19704 1.96511 3.28915 2.05722 3.40277 2.05722Z"
            fill="#E70000"
          />
          <path
            d="M3.40277 1.23447H5.59722C5.71084 1.23447 5.80296 1.14235 5.80296 1.02873C5.80296 0.915116 5.71084 0.822998 5.59722 0.822998H3.40277C3.28915 0.822998 3.19704 0.915116 3.19704 1.02873C3.19704 1.14235 3.28915 1.23447 3.40277 1.23447Z"
            fill="#E70000"
          />
          <path
            d="M3.40277 0.411473H5.59722C5.71084 0.411473 5.80296 0.319355 5.80296 0.205736C5.80296 0.092118 5.71084 0 5.59722 0H3.40277C3.28915 0 3.19704 0.092118 3.19704 0.205736C3.19704 0.319381 3.28915 0.411473 3.40277 0.411473Z"
            fill="#E70000"
          />
        </g>
        <defs>
          <clipPath id="clip0_1578_7318">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const IncreaseIcon = ({ size = 9 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
      >
        <g clip-path="url(#clip0_1578_7366)">
          <path
            d="M1.74589 2.8994C1.78419 2.94072 1.83774 2.96453 1.89408 2.96527L3.26561 2.96527L3.26561 6.27065C3.26561 6.39408 3.29305 6.53125 3.40275 6.53125L5.5972 6.53125C5.73636 6.51495 5.84809 6.40881 5.87151 6.27065L5.87151 2.96527L7.10587 2.96527C7.21949 2.96377 7.31038 2.87047 7.30888 2.75685C7.30815 2.70052 7.28434 2.64696 7.24301 2.60866L4.63712 0.0576046C4.5629 -0.018132 4.44133 -0.0193927 4.36559 0.0548212C4.36465 0.0557401 4.36373 0.0566594 4.36281 0.0576045L1.75691 2.60866C1.67359 2.68589 1.66866 2.81605 1.74589 2.8994Z"
            fill="#12794F"
          />
          <path
            d="M5.59718 6.94278L3.40273 6.94278C3.28911 6.94278 3.19699 7.03489 3.19699 7.14851C3.19699 7.26213 3.28911 7.35425 3.40273 7.35425L5.59718 7.35425C5.7108 7.35425 5.80292 7.26213 5.80292 7.14851C5.80292 7.03489 5.7108 6.94278 5.59718 6.94278Z"
            fill="#12794F"
          />
          <path
            d="M5.59718 7.76553L3.40273 7.76553C3.28911 7.76553 3.19699 7.85765 3.19699 7.97127C3.19699 8.08488 3.28911 8.177 3.40273 8.177L5.59718 8.177C5.7108 8.177 5.80292 8.08488 5.80292 7.97127C5.80292 7.85765 5.7108 7.76553 5.59718 7.76553Z"
            fill="#12794F"
          />
          <path
            d="M5.59718 8.58853L3.40273 8.58853C3.28911 8.58853 3.19699 8.68065 3.19699 8.79426C3.19699 8.90788 3.28911 9 3.40273 9L5.59718 9C5.7108 9 5.80292 8.90788 5.80292 8.79426C5.80292 8.68062 5.7108 8.58853 5.59718 8.58853Z"
            fill="#12794F"
          />
        </g>
        <defs>
          <clipPath id="clip0_1578_7366">
            <rect
              width={size}
              height={size}
              fill="white"
              transform="translate(9 9) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M6.4165 9.16667L11.2694 13.75L15.5832 9.16667"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const FilledCircleIcon = ({ color = "transparent" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <circle cx="7.5" cy="7.5" r="7" fill={color} />
    </svg>
  );
};
export const CircleIcon = ({ color = "#C4C4C4" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <circle cx="7.5" cy="7.5" r="7" stroke="#C4C4C4" />
    </svg>
  );
};
export const SettingsIcon = ({ color = "#BAB7B7" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_18_749)">
        <path
          d="M0.56056 3.42693C0.252001 3.42693 -4.14279e-08 3.17493 -2.79613e-08 2.86685L-2.44644e-08 2.78685C-1.09768e-08 2.47829 0.252001 2.22717 0.56056 2.22717L15.4399 2.22717C15.7478 2.22717 16 2.47829 16 2.78677L16 2.86677C16 3.17485 15.7478 3.42685 15.4399 3.42685L0.56056 3.42693Z"
          fill={color}
        />
        <path
          d="M8.71997 4.4519C7.82251 4.4519 7.09497 3.72437 7.09497 2.8269C7.09497 1.92944 7.82251 1.2019 8.71997 1.2019C9.61743 1.2019 10.345 1.92944 10.345 2.8269C10.345 3.72437 9.61743 4.4519 8.71997 4.4519Z"
          fill="white"
          stroke={color}
          stroke-width="0.75"
        />
        <path
          d="M0.56056 7.92741C0.252001 7.92741 -4.14384e-08 7.67493 -2.79613e-08 7.36661L-2.45028e-08 7.28749C-1.10362e-08 6.97941 0.252001 6.72693 0.56056 6.72693L15.4399 6.72693C15.7478 6.72693 16 6.97941 16 7.28749L16 7.36661C16 7.67517 15.7478 7.92741 15.4399 7.92741L0.56056 7.92741Z"
          fill={color}
        />
        <path
          d="M3.60043 8.95174C2.70277 8.95174 1.97511 8.22423 1.97511 7.32706C1.97511 6.43013 2.70293 5.7019 3.60043 5.7019C4.49732 5.7019 5.22559 6.43017 5.22559 7.32706C5.22559 8.22427 4.49757 8.95174 3.60043 8.95174Z"
          fill="white"
          stroke={color}
          stroke-width="0.75"
        />
        <path
          d="M0.56056 12.4267C0.252001 12.4267 -4.14174e-08 12.1742 -2.79718e-08 11.8666L-2.44818e-08 11.7868C-1.10082e-08 11.4785 0.252001 11.2267 0.56056 11.2267L15.4399 11.2267C15.7478 11.2267 16 11.4787 16 11.7868L16 11.8666C16 12.1744 15.7478 12.4267 15.4399 12.4267L0.56056 12.4267Z"
          fill={color}
        />
        <path
          d="M12.0396 13.4515C11.1424 13.4515 10.4147 12.7239 10.4147 11.8263C10.4147 10.9293 11.1422 10.2017 12.0396 10.2017C12.9374 10.2017 13.6646 10.9293 13.6646 11.8263C13.6646 12.7239 12.9372 13.4515 12.0396 13.4515Z"
          fill="white"
          stroke={color}
          stroke-width="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_749">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ReverseTriangle = ({ color }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="4"
        viewBox="0 0 6 4"
        fill="none"
      >
        <path d="M3 4L0.401924 0.25L5.59808 0.25L3 4Z" fill={color} />
      </svg>
    </>
  );
};
