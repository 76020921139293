import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './components/Login/Login';
import ResetPassword from './components/Login/ResetPassword/ResetPassword';
import Homepage from "./pages/homepage";
import MetaSearch from './pages/metasearch';
import LostPassword from './components/Login/LostPassword';

function App() {
    return (<div>
        <Router>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/home" element={<Homepage/>}/>
                <Route path="/metasearch" element={<MetaSearch/>}/>
                <Route path="/lost-password" element={<LostPassword/>}/>
            </Routes>
        </Router>
    </div>);
}

export default App;
