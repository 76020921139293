import React, {useState} from 'react';
import {Button, Grid, TextField, InputLabel} from '@mui/material';
import {APP_RESET_PASSWORD_API} from '../../../constants/api_urls';
import axios from 'axios';

const NewPasswordForm = ({setPasswordUpdating, email}) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState("")
    const token = sessionStorage.getItem("token")


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            const response = await axios.post(`${APP_RESET_PASSWORD_API}`, {
                password: newPassword,
                confirm_password,
                token
            });


            if (response?.data) {
                setPasswordUpdating('Your password has been successfully changed');
                sessionStorage.removeItem("token")

                //alert('Password updated successfully');
            } else {


                throw new Error('Password update failed');
            }
        } catch (error) {
            if (error?.response?.data?.error_message) {
                setErrorMessage(error?.response?.data?.error_message)
            }
            console.error('Error:', error);
            // Handle error
        }
    };

    return (<form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}> {/* Full width for Password */}
                <InputLabel htmlFor="new password" shrink>
                    New Password
                </InputLabel>
                <TextField
                    id="newpassword"
                    type="password"
                    variant="outlined"
                    required
                    value={newPassword}
                    onChange={(e) => {
                        setErrorMessage("")
                        setNewPassword(e.target.value);
                    }}
                    InputProps={{
                        sx: {
                            height: '30px', // Set fixed height
                            width: '298px', marginTop: 0,
                        },
                    }}
                    InputLabelProps={{
                        shrink: true, // Keep label in place
                        sx: {
                            marginTop: 0, // Remove top margin
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}> {/* Full width for Password */}
                <InputLabel htmlFor="confirm password" shrink>
                    Confirm Password
                </InputLabel>
                <TextField
                    id="confirm_password"
                    type="password"
                    variant="outlined"
                    required
                    value={confirm_password}
                    onChange={(e) => {
                        setErrorMessage("")
                        setConfirmPassword(e.target.value);
                    }}
                    InputProps={{
                        sx: {
                            height: '30px', // Set fixed height
                            marginTop: 0, // Remove top margin
                            width: '298px',
                        },
                    }}
                    InputLabelProps={{
                        shrink: true, // Keep label in place
                        sx: {
                            marginTop: 0, // Remove top margin
                        },
                    }}
                />
            </Grid>
        </Grid>
        {errorMessage && <div style={{color: "red"}}>{errorMessage || ""}</div>}
        <Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{
                        width: "100px",
                        height: "44px",
                        flexShrink: "0",
                        borderRadius: "10px",
                        background: "#61D5A9",
                        marginTop: '35px'
                    }}
                >
                    Save
                </Button>
            </Grid>
        </Grid>

    </form>);
};

export default NewPasswordForm;
