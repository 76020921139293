import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const BiAxialLineChart = ({ data, colors }) => {
  const lines = useMemo(() => {
    if (!data?.[0]) {
      return [];
    }
    return Object.keys(data?.[0])?.filter((line) => line !== "name") ?? [];
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {lines?.length <= 2 && <CartesianGrid vertical={false} />}
        <XAxis
          style={{
            marginTop: "10px",
            fontSize: "8px",
            fontFamily: "inter !important",
          }}
          dataKey="name"
        />
        {lines?.length <= 2 && (
          <YAxis
            style={{
              marginTop: "10px",
              fontSize: "8px",
              fontFamily: "inter !important",
            }}
            tickLine={false}
            axisLine={false}
            label={
              <Label
                value={lines?.[0]}
                style={{
                  textAnchor: "middle",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
                position={"left"}
                angle={-90}
              />
            }
            yAxisId="left"
          />
        )}
        {lines?.length === 2 && (
          <YAxis
            axisLine={false}
            style={{
              marginTop: "10px",
              fontSize: "8px",
              fontFamily: "inter !important",
            }}
            tickLine={false}
            label={
              <Label
                value={lines?.[1]}
                style={{
                  textAnchor: "middle",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
                position={"right"}
                angle={90}
              />
            }
            yAxisId="right"
            orientation="right"
          />
        )}
        <Tooltip />

        {lines
          .filter((line) => !line.startsWith("compare"))
          .map((line, index) => {
            console.log(line, "ll");
            return (
              <Line
                yAxisId={index === 0 ? "left" : index === 1 ? "right" : ""}
                type="linear"
                dataKey={line}
                stroke={colors[index]}
                strokeWidth={2}
              />
            );
          })}
        {lines
          .filter((line) => line.startsWith("compare"))
          .map((line, index) => {
            return (
              <Line
                yAxisId={index === 0 ? "left" : index === 1 ? "right" : ""}
                type="linear"
                dataKey={line}
                stroke={colors[index]}
                dot={false}
                strokeDasharray={"3 3"}
                strokeWidth={2}
              />
            );
          })}
      </LineChart>
    </ResponsiveContainer>
  );
};
export default BiAxialLineChart;
