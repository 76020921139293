import { Switch } from "@mui/material";
import "./Switch.scss";

const CustomSwitch = ({ checked = true, onChange, disabled }) => {
  return (
    <Switch
      sx={{
        height: "fit-content",
        padding: 0,
        paddingLeft: "5px",
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: "#61D5A9",
          height: 16,
          width: 26,
        },
        "& .MuiSwitch-track": {
          height: 16,
          width: 26,
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: "#0A7D53",
          height: "16px !important",
          width: 16,
          boxShadow: "none",
          margin: "0px",
          padding: 0,
        },

        "& .MuiSwitch-switchBase": {
          padding: 0,
        },
      }}
      checked={checked}
      className="switch"
      onChange={onChange}
    />
  );
};
export default CustomSwitch;
