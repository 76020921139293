import "./Navbar.scss";
import React, { useState } from "react";
import AccountDropdown from "./AccountDropdown";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
//constants
import { PRODUCT_MENU_LIST } from "../../constants/productmenu";
// images
import LogoIcon from "../../assets/images/logo.svg";
import AppsIcon from "../../assets/images/apps.svg";

export default function Navbar() {
  const navigate = useNavigate();
  const email = sessionStorage.getItem("username") || "";
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [appsMenuOpen, setAppsMenuOpen] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    navigate("/");
    sessionStorage.clear();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={"custom-menu"}
    >
      <MenuItem onClick={handleMenuClose}>Users</MenuItem>
      <MenuItem className={"mt-4"} onClick={handleLogout}>
        Log Out
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className={"user-email"}>
        {email}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
        >
          <AccountCircle color="red" />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const appsId = appsMenuOpen ? "simple-popover" : undefined;
  const handleAppsMenu = () => {
    setAppsMenuOpen(!appsMenuOpen);
  };

  const handleAppsMenuClose = () => {
    setAppsMenuOpen(null);
  };

  const getSplittedMenuItems = (menuItems) => {
    const totalItems = menuItems.length;
    const leftItemCount = Math.ceil(totalItems / 2) || 0;
    return (
      <div className={"split-container"}>
        <div className={"left-container"}>
          {menuItems.slice(0, leftItemCount).map(({ icon, text }) => (
            <div className={"menu-item"}>
              <img src={icon} className={"menu-icon"} alt={"menu icon"} />{" "}
              <span className={"menu-text"}>{text}</span>
            </div>
          ))}
        </div>
        <div className={"right-container"}>
          {menuItems.slice(leftItemCount).map(({ icon, text }) => (
            <div className={"menu-item"}>
              <img src={icon} className={"menu-icon"} alt={"menu icon"} />{" "}
              <span className={"menu-text"}>{text}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }} className={"navbar"}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#61D5A9",
          padding: "5.5px 0",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: {
                xs: "none",
                sm: "block",
                paddingTop: "5px",
                paddingLeft: "15px",
              },
            }}
          >
            <Link to={"/home"}>
              <img src={LogoIcon} alt={"Logo"} />
            </Link>
          </Typography>

          <Box sx={{ marginLeft: "70px" }}>
            <AccountDropdown label={"Accounts"} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              olor="inherit"
              aria-describedby={appsId}
              id={{ appsId }}
              onClick={handleAppsMenu}
            >
              <img src={AppsIcon} alt={"Apps"} />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleAppsMenu}
              color="inherit"
            >
              <img src={AppsIcon} alt={"Apps"} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Popover
        id={appsId}
        open={Boolean(appsMenuOpen)}
        anchorEl={appsMenuOpen}
        onClose={handleAppsMenuClose}
        className={"product-menu"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className={"apps-menu"}>
          {PRODUCT_MENU_LIST.length
            ? PRODUCT_MENU_LIST.map(
                ({ heading, className = "", menuItems }) => (
                  <div className={"menu-container"}>
                    <div className={"menu-heading"}>{heading}</div>
                    {className !== "split" ? (
                      <div className={"menu-items"}>
                        {menuItems?.length
                          ? menuItems.map(({ icon, text, url = "" }) => (
                              <a
                                className={"menu-item"}
                                target="_blank"
                                href={url}
                              >
                                <img
                                  src={icon}
                                  className={"menu-icon"}
                                  alt={"menu icon"}
                                />{" "}
                                <span className={"menu-text"}>{text}</span>
                              </a>
                            ))
                          : ""}
                      </div>
                    ) : (
                      getSplittedMenuItems(menuItems)
                    )}
                  </div>
                )
              )
            : ""}
        </div>
      </Popover>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
