import {
  FilledCircleIcon,
  CircleIcon,
  SettingsIcon,
} from "../../../assets/images/Icons";
import { Box } from "@mui/material";
import SelectMenu from "./select_menu";
import { useMemo, useRef, useState } from "react";
import "./chartContainer.scss";
import CustomSwitch from "../../../components/Switch";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import GraphOptions from "./graph_options";
import { comparitionData, sampleChartData } from "../../../mock/chartData";
import { sampleAxisData } from "../../../mock/chartSampleAxisData";
import BiAxialLineChart from "../../../components/BiAxialLineChart";

export default function ChartContainer() {
  const menuOptions = [
    {
      id: "spent",
      label: "Spent",
    },
    {
      id: "generated",
      label: "Generated",
    },
    {
      id: "clicks",
      label: "Clicks",
    },
    {
      id: "cpc",
      label: "CPC",
    },
    {
      id: "revenue_perclick",
      label: "Revenue per Click",
    },
    {
      id: "bookingcount",
      label: "Bookings",
    },
    {
      id: "conversion",
      label: "Conversion",
    },
    {
      id: "roi",
      label: "ROAS",
    },
  ];
  const data = [
    {
      name: "",
      data: [
        [1721150251172 - 10 * 24 * 60 * 60 * 1000, 43934],
        [1721150251172, 48656],
        [1721150251172 + 10 * 24 * 60 * 60 * 1000, 65165],
      ],
      marker: { enabled: false },
    },
    {
      name: "",
      data: [
        [1721150251172 - 10 * 24 * 60 * 60 * 1000, 54878],
        [1721150251172, 66565],
        [1721150251172 + 10 * 24 * 60 * 60 * 1000, 92888],
      ],
      marker: { enabled: false },
    },
    {
      name: "",
      data: [
        [1721150251172 - 10 * 24 * 60 * 60 * 1000, 87878],
        [1721150251172, 56834],
        [1721150251172 + 10 * 24 * 60 * 60 * 1000, 54376],
      ],
      dashStyle: "dash",
      marker: { enabled: false },
    },
    {
      name: "",
      data: [
        [1721150251172 - 10 * 24 * 60 * 60 * 1000, 77878],
        [1721150251172, 66834],
        [1721150251172 + 10 * 24 * 60 * 60 * 1000, 44376],
      ],
      dashStyle: "dash",
      marker: { enabled: false },
    },
  ];
  const [option1, setOption1] = useState(menuOptions[0].id);
  const [option2, setOption2] = useState(menuOptions[1].id);
  const [option3, setOption3] = useState("noMetric");
  const [option4, setOption4] = useState("noMetric");
  const [compareOption1, setCompareOption1] = useState("noMetric");
  const [compareOption2, setCompareOption2] = useState("noMetric");
  const [compareOption3, setCompareOption3] = useState("noMetric");
  const [compareOption4, setCompareOption4] = useState("noMetric");

  const filterRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [filter, setFilter] = useState("daily");

  const chartData = [];
  const getColors = useMemo(() => {
    const colors = ["#12794F", "#61D5A9", "#035661", "#10C4A8"];
    [option1, option2, option3, option4]?.map((i, index) => {
      if (i === "noMetric") {
        colors.splice(index, 1);
      }
    });
    console.log(colors.filter((i) => i !== null));
    return colors;
  }, [option1, option2, option3, option4]);
  Object.entries(sampleChartData[filter])?.forEach((item, index) => {
    const lines = [option1, option2, option3, option4]
      .filter((op) => op !== "noMetric")
      ?.reduce((acc, i) => {
        return (acc = {
          ...acc,
          [i]: sampleChartData?.[filter]?.[i]?.[index] ?? undefined,
        });
      }, {});
    const lines2 = [
      compareOption1,
      compareOption2,
      compareOption3,
      compareOption4,
    ]
      .filter((op) => op !== "noMetric")
      ?.reduce((acc, i) => {
        const key = "compare" + i;
        return (acc = {
          ...acc,
          [key]: comparitionData?.[filter]?.[i]?.[index] ?? undefined,
        });
      }, {});
    console.log(lines, "lines");
    if (Object.values(lines).every((i) => i === undefined)) {
      return;
    }
    chartData.push({
      name: sampleAxisData[filter][index],
      ...lines,
      ...lines2,
    });
  });

  console.log(Object.entries(sampleChartData[filter]), chartData, "chartData");
  return (
    <>
      <Box
        sx={{
          width: "calc(100%-32px)",
          marginTop: "21px",
          backgroundColor: "white",
          paddingTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            marginLeft: "auto",
            paddingBottom: "10px",
          }}
        >
          <Box className="menu">
            <Box className="icon">
              {option1 === "noMetric" ? (
                <CircleIcon />
              ) : (
                <FilledCircleIcon color={"#15825A"} />
              )}
            </Box>

            <SelectMenu
              options={menuOptions?.filter(
                (item) =>
                  item?.id !== option2 &&
                  item?.id !== option3 &&
                  item?.id !== option4
              )}
              value={option1}
              handleChange={(v) => setOption1(v)}
              action={
                <div>
                  {" "}
                  <Box sx={{ fontSize: 12 }}>
                    {" "}
                    <CustomSwitch
                      checked={option1 !== "noMetric"}
                      disabled={option1 === "noMetric"}
                      onChange={() => {
                        if (option1 !== "noMetric") setOption1("noMetric");
                      }}
                    />{" "}
                    Show metric{" "}
                  </Box>
                </div>
              }
            />
          </Box>
          <Box className="menu">
            <Box className="icon">
              {option2 === "noMetric" ? (
                <CircleIcon />
              ) : (
                <FilledCircleIcon color={"#68D5AC"} />
              )}
            </Box>

            <SelectMenu
              options={menuOptions?.filter(
                (item) =>
                  item?.id !== option1 &&
                  item?.id !== option3 &&
                  item?.id !== option4
              )}
              value={option2}
              handleChange={(v) => setOption2(v)}
              action={
                <Box sx={{ fontSize: 12 }}>
                  {" "}
                  <CustomSwitch
                    checked={option2 !== "noMetric"}
                    onChange={() => {
                      if (option2 !== "noMetric") setOption2("noMetric");
                    }}
                  />{" "}
                  Show metric{" "}
                </Box>
              }
            />
          </Box>
          <Box className="menu">
            <Box className="icon">
              {option3 === "noMetric" ? (
                <CircleIcon />
              ) : (
                <FilledCircleIcon
                  color={option3 === "noMetric" ? "transparent" : "#035661"}
                />
              )}
            </Box>

            <SelectMenu
              options={menuOptions?.filter(
                (item) =>
                  item?.id !== option1 &&
                  item?.id !== option2 &&
                  item?.id !== option4
              )}
              value={option3}
              handleChange={(v) => setOption3(v)}
              action={
                <Box sx={{ fontSize: 12 }}>
                  {" "}
                  <CustomSwitch
                    checked={option3 !== "noMetric"}
                    onChange={() => {
                      if (option3 !== "noMetric") setOption3("noMetric");
                    }}
                  />{" "}
                  Show metric{" "}
                </Box>
              }
            />
          </Box>
          <Box className="menu">
            <Box className="icon">
              {option4 === "noMetric" ? (
                <CircleIcon />
              ) : (
                <FilledCircleIcon
                  color={option4 === "noMetric" ? "transparent" : "#10C4A8"}
                />
              )}
            </Box>

            <SelectMenu
              options={menuOptions?.filter(
                (item) =>
                  item?.id !== option2 &&
                  item?.id !== option3 &&
                  item?.id !== option1
              )}
              value={option4}
              handleChange={(v) => setOption4(v)}
              action={
                <Box sx={{ fontSize: 12 }}>
                  {" "}
                  <CustomSwitch
                    checked={option4 !== "noMetric"}
                    onChange={() => {
                      if (option4 !== "noMetric") setOption4("noMetric");
                    }}
                  />{" "}
                  Show metric{" "}
                </Box>
              }
            />
          </Box>

          <Box className="filters" sx={{ paddingTop: "2px" }}>
            <Box
              onClick={() => {
                setIsFilterMenuOpen(!isFilterMenuOpen);
              }}
              ref={filterRef}
              sx={{ marginRight: "30px" }}
            >
              <SettingsIcon color="#12794F" />
              <GraphOptions
                open={isFilterMenuOpen}
                anchorEl={filterRef}
                onClose={() => {
                  setIsFilterMenuOpen(false);
                }}
                onChange={(value) => {
                  setFilter(value);
                }}
              />
            </Box>
            <Box
              sx={{ marginRight: "20px" }}
              onClick={() => {
                setIsChartCollapsed(!isChartCollapsed);
              }}
            >
              {
                <ExpandCircleDownOutlined
                  sx={{
                    color: "#12794F",
                    transform: isChartCollapsed
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }}
                />
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "197px",
            display: isChartCollapsed ? "none" : "block",
          }}
        >
          <BiAxialLineChart data={chartData} colors={getColors} />
        </Box>
      </Box>
    </>
  );
}
