import {makeStyles} from "tss-react/mui";

export const useStyles = makeStyles()((theme) => {
    return {
        container: {
            paddingBottom: 15,
        }, header: {
            color: "#BAB7B7", fontSize: 14,
        }, footer: {
            marginTop: 15, paddingInline: 18,
        }, divider: {
            marginBottom: 15, color: theme.palette.grey[100],
        }, downloadText: {
            cursor: "pointer", fontSize: 12,
        }, menuItem: {
            paddingBlock: "2px",
        },
    };
});
