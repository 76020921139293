import React from "react";
import "./Sidebar.scss";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DownArrow from "../../../assets/images/DownArrow.svg";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import {useNavigate} from "react-router-dom";

const drawerWidth = 150, openedMixin = (theme) => ({
    width: drawerWidth, transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
    }), overflowX: "hidden",
}), closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), overflowX: "hidden", width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth, flexShrink: 0, whiteSpace: "nowrap", boxSizing: "border-box", ...(open && {
        ...openedMixin(theme), "& .MuiDrawer-paper": openedMixin(theme),
    }), ...(!open && {
        ...closedMixin(theme), "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));
const MenuItem = ({item, open, handleClick}) => (<>
        <ListItem button onClick={() => handleClick(item.id)}>
            <ListItemText primary={item.label}/>
            {item.children ? (open ? (<img src={DownArrow} alt={"Down Arrow"}/>) : (<ExpandMore/>)) : null}
        </ListItem>
        {item.children && (<Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.children.map((child) => (<div className="menuItem-child">
                            <MenuItem
                                key={child.id}
                                item={child}
                                open={open[child.id]}
                                handleClick={handleClick}
                            />
                        </div>))}
                </List>
            </Collapse>)}
    </>);

export default function CustomDrawer({
                                         menuItems, initialItems, moreItems, currentSection, setCurrentSection,
                                     }) {
    const navigate = useNavigate();

    const handleMenuItemClick = (name, url) => {
        navigate(url);
        setCurrentSection(name === "META SEARCH" ? "Campaign Manager" : name);
    };

    return (<Box
            sx={{
                display: "flex", justifyContent: "center", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
            }}
            className={"sidebar"}
        >
            <Box>
                <List>
                    {currentSection === null && (<>
                            <div className="my-products-title">
                                <span className={"underline"}>My Products</span>
                            </div>

                            {initialItems.map(({name, icon, url}) => (<ListItem
                                    onClick={() => handleMenuItemClick(name, url)}
                                    key={name}
                                    disablePadding
                                    className={"list-menu"}
                                >
                                    <img alt={name} src={icon} className={"menu-icon"}/>
                                    <div className={"list-menu-name"}>{name}</div>
                                </ListItem>))}
                            <div className={"coming-soon"}>
                                <span className={"underline"}>Coming Soon</span>
                            </div>

                            {moreItems.map(({name, icon}) => (<ListItem
                                    key={name}
                                    disablePadding
                                    className={"list-menu disabled"}
                                >
                                    <img alt={name} src={icon} className={"menu-icon"}/>
                                    <div className={"list-menu-name"}>{name}</div>
                                </ListItem>))}
                        </>)}
                    {currentSection !== null && (<div className="expanded-container">
                            <div className="title">Campaign Manager</div>
                            {menuItems.map((item) => (<MenuItem
                                    item={item}
                                    open={true}
                                    handleClick={() => null}
                                    className="menu-item"
                                />))}
                        </div>)}
                </List>
            </Box>
        </Box>);
}
