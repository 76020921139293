import "./metasearch.scss";
import React, { useState } from "react";
import PrimarySearchAppBar from "../../components/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import {
  homepageMenuItems,
  homepageSideMyMenuItems,
  homepageSideMoreMenuItems,
} from "../../constants/productmenu";
import DefaultLayout from "../../layout/defaultLayout";

const MetaSearch = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("");

  const MetaSearch = () => <div>Meta Search Content</div>;
  const SearchAds = () => <div>Search Ads Content</div>;
  const PMax = () => <div>PMax Content</div>;
  const SocialAds = () => <div>Social Ads Content</div>;
  const OtaAds = () => <div>OTA Ads Content</div>;

  const renderContent = () => {
    switch (activeComponent) {
      case "META SEARCH":
        return <MetaSearch />;
      case "SEARCH ADS":
        return <SearchAds />;
      case "PMAX":
        return <PMax />;
      case "SOCIAL ADS":
        return <SocialAds />;
      case "OTA ADS":
        return <OtaAds />;
      default:
        return <div>Select a menu item</div>;
    }
  };
  return (
    <div className="metasearch">
      {/* <PrimarySearchAppBar/>
        <Sidebar
            menuItems={homepageMenuItems}
            moreItems={homepageSideMoreMenuItems}
            initialItems={homepageSideMyMenuItems}
            renderContent={renderContent}
            setActiveComponent={setActiveComponent}
            setOpen={setOpen}
            open={open}
            currentSection={currentSection} setCurrentSection={setCurrentSection}

        /> */}
      <DefaultLayout page="metaSearch" > Metasearch </DefaultLayout>
    </div>
  );
};
export default MetaSearch;
