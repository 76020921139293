import {
    MenuItem, Select, FormControl, Divider, Typography,
} from "@mui/material";
import {SettingsIcon} from "../../../../assets/images/Icons";
import "./select_menu.scss";
import {KeyboardArrowDown} from "@mui/icons-material";

const SelectMenu = ({value, options, action, handleChange}) => {
    return (<>
        <FormControl>
            <Select
                disableUnderline
                onChange={(e) => {
                    handleChange(e.target.value);
                }}
                IconComponent={(props) => (<KeyboardArrowDown sx={{color: "black !important"}} {...props} />)}
                value={value}
                variant="standard"
                MenuProps={{
                    PaperProps: {sx: {borderRadius: "10px"}},
                }}
                sx={{
                    "& .MuiInputBase-root.MuiInput-root.MuiSelect-root": {
                        backgroundColor: "transparent !important",
                    },
                }}
                renderValue={(o) => {
                    return options?.find((op) => op.id === o) ? options?.find((op) => op.id === o)?.label : "No value";
                }}
            >
                <MenuItem disabled sx={{marginTop: "5px"}} className="menuItem">
                    {" "}
                    <Typography sx={{marginRight: "55px", fontSize: 12}}>
                        Change Metric
                    </Typography>{" "}
                    <SettingsIcon/>{" "}
                </MenuItem>

                {options?.map((option) => {
                    return (<MenuItem className="menuItem" value={option?.id}>
                        {" "}
                        {option?.label}{" "}
                    </MenuItem>);
                })}
                <Divider
                    sx={{backgroundColor: "#CCC", width: "80%", marginInline: "auto"}}
                ></Divider>
                <MenuItem
                    sx={{
                        paddingLeft: "20px !important", marginBlock: 0, justifyContent: "flex-start",
                    }}
                    disableGutters
                >
                    {action}
                </MenuItem>
            </Select>
        </FormControl>
    </>);
};
export default SelectMenu;
