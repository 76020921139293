// images
import IconMetaSearch from "../assets/images/IconMetaSearch.svg";
import IconSocialAds from "../assets/images/IconSocialAds.svg";
import IconSearchAds from "../assets/images/IconSearchAds.svg";
import IconOtaAds from "../assets/images/IconOtaAds.svg";
import IconPMax from "../assets/images/IconPMax.svg";
import IconSeo from "../assets/images/IconSeo.svg";
import IconCms from "../assets/images/IconCms.svg";
import IconRateMatch from "../assets/images/IconRateMatch.svg";
import IconPushTech from "../assets/images/IconPushTech.svg";
import {
  REACT_APP_ICONCMS_URL,
  REACT_APP_RATEMATCH_URL,
  REACT_APP_ICONSEO_URL,
  REACT_APP_PUSHTECH_URL,
} from "./external_urls";

export const homepageSideMyMenuItems = [
  {
    name: "META SEARCH",
    icon: IconMetaSearch,
    disabled: false,
    url: "/metasearch",
  },
  {
    name: "SEARCH ADS",
    icon: IconSearchAds,
    disabled: false,
    url: "/searchads",
  },
  {
    name: "PMAX",
    icon: IconPMax,
    disabled: false,
    url: "/pmax",
  },
];

export const PRODUCT_MENU_LIST = [
  {
    heading: "Paid Ads",
    className: "split",
    menuItems: [
      {
        icon: IconMetaSearch,
        text: "META SEARCH",
        url: "/",
      },
      {
        icon: IconSearchAds,
        text: "SEARCH ADS",
        external: true,
        url: "/",
      },
      {
        icon: IconPMax,
        text: "PMAX",
        external: true,
        url: "/",
      },
      ,
    ],
  },
  {
    heading: "Website",
    menuItems: [
      {
        icon: IconSeo,
        text: "SEO",
        url: REACT_APP_ICONSEO_URL,
      },
      {
        icon: IconCms,
        text: "CMS",
        url: REACT_APP_ICONCMS_URL,
      },
    ],
  },
  {
    heading: "Rate Match",
    menuItems: [
      {
        icon: IconRateMatch,
        text: "RATE MATCH",
        url: REACT_APP_RATEMATCH_URL,
      },
    ],
  },
  {
    heading: "CRM",
    menuItems: [
      {
        icon: IconPushTech,
        text: "PUSHTECH",
        url: REACT_APP_PUSHTECH_URL,
      },
    ],
  },
];

export const homepageSideMoreMenuItems = [
  {
    name: "SOCIAL ADS",
    icon: IconSocialAds,
    disabled: false,
  },
  {
    name: "OTA ADS",
    icon: IconOtaAds,
    disabled: false,
  },
];

export const homepageMenuItems = [
  {
    id: "settings",
    label: "Settings",

    children: [
      { id: "groups", label: "Groups" },
      { id: "bidding-center", label: "Bidding Center" },
      {
        id: "bidding-offline",
        label: "Bidding Offline",
      },
    ],
  },
  {
    id: "reports",
    label: "Reports",

    children: [
      { id: "report-center", label: "Report Center" },
      { id: "alert-center", label: "Alert Center" },
    ],
  },
  {
    id: "reservations",
    label: "Reservations",

    children: [
      { id: "reservations-main", label: "Reservations" },
      {
        id: "bulk-reservations",
        label: "Bulk Reservations",
      },
    ],
  },
  {
    id: "insights",
    label: "Insights",

    children: [
      { id: "price-competitiveness", label: "Price Competitiveness" },
      {
        id: "user-checkin-dates",
        label: "User Checkin Dates",
      },
    ],
  },
  {
    id: "matching-status",
    label: "Matching Status",

    children: [
      { id: "price-competitiveness-status", label: "Price Competitiveness" },
      {
        id: "user-checkin-dates-status",
        label: "User Checkin Dates",
      },
    ],
  },
];
