import "./AccountDropdown.scss";
import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {Menu, TextField} from "@mui/material";
import {APP_ACCOUNT_API_ENDPOINT} from "../../../constants/api_urls";
import axios from "axios";
import {KeyboardArrowDown} from "@mui/icons-material";


export default function AccountDropdown({label}) {
    const [selectedValues, setSelectedValues] = React.useState();
    const [accounts, setAccounts] = React.useState([]);
    const [searchedAccounts, setSearchedAccounts] = React.useState([]);
    const handleChange = (event) => {
        setSelectedValues(event.target.value);
    };
    const token = sessionStorage.getItem("token") || "";

    React.useEffect(() => {
        axios
            .get(`${APP_ACCOUNT_API_ENDPOINT}/api/accounts/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setAccounts(res.data.data);
                setSearchedAccounts(res.data.data);
                console.log("Res", res);
            });
    }, []);
    const handleSearch = (e) => {
        const search = e.target.value.toLowerCase();
        if (search === "a" || search === "A") {
            e.preventDefault();
        }
        if (search.length === 0) {
            setSearchedAccounts(accounts);
            return;
        }

        const tempData = [...accounts];
        setSearchedAccounts(tempData?.filter((item) => {
            const accountId = item?.account_id?.toString().toLowerCase();
            const accountName = item?.account_name?.toString().toLowerCase();
            console.log(item);
            if (accountId?.includes(search) || accountName?.includes(search)) {
                return true;
            }
            return false;
        }));
    };
    const [isOpen, setIsOpen] = React.useState(false);
    const accountRef = React.useRef(null);
    return <Box sx={{minWidth: 120}} className={"account"}>
            <Typography
                sx={{
                    color: "#12794F", fontSize: "12px", fontWeight: 400, textAlign: "left",
                }}
            >
                {label || ""}
            </Typography>
            <FormControl sx={{minWidth: 170}}>
                <div
                    className="selected"
                    ref={accountRef}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {" "}
                    <div className="selected-label">{selectedValues?.account_name} </div>
                    <div>
                        {" "}
                        <KeyboardArrowDown/>{" "}
                    </div>
                    {" "}
                </div>
                <Menu
                    labelId="select-label"
                    open={isOpen}
                    id="menu"
                    anchorEl={accountRef.current}
                    value={selectedValues}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    IconComponent={(props) => <KeyboardArrowDown sx={{color: "black !important"}} {...props} />}
                    PaperProps={{
                        sx: {
                            marginTop: "15px", width: 190, borderRadius: "10px",
                        },
                    }}
                    anchorOrigin={{
                        vertical: "bottom", horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top", horizontal: "center",
                    }}
                >
                    <TextField
                        sx={{
                            "& ::placeholder": {
                                fontSize: 10,
                            },
                        }}
                        variant="standard"
                        onChange={(e) => {
                            handleSearch(e);
                        }}
                        placeholder="Search"
                        className="select-search"
                    />
                    <Box
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            scrollbarWidth: "thin",
                        }}
                    >
                        {[
                            ...searchedAccounts,
                            ...searchedAccounts,
                            ...searchedAccounts,
                        ]?.map((account)=>{
                            return <MenuItem
                                    sx={{fontSize: 12}}
                                    value={account.account_id}
                                    onClick={(e) => {
                                        setSelectedValues(account);
                                        setIsOpen(false);
                                    }}
                                >
                                    {" "}
                                    {account.account_name}{" "}
                                </MenuItem>;
                        })}
                    </Box>
                </Menu>
            </FormControl>
        </Box>;
}
