export const sampleChartData = {
  daily: {
    spent: [
      193.23, 158.8, 170.57, 182.95, 161.61, 159.91, 153.7, 217.33, 176.59,
      157.53, 163.43, 111.47, 146.44, 139.18, 136.14, 130.44, 183.04, 142.49,
      167.12, 150.93, 157.62, 169.64, 164.35, 76.74,
    ],
    generated: [
      5274.57, 22843.66, 6977.25, 7381.83, 9402.72, 3671.83, 12216.37, 8480.01,
      8684.56, 9089.98, 18323.65, 6398.17, 7729.98, 12012.73, 5842.72, 7627.27,
      7470.01, 10816.37, 13308.18, 7417.28, 2881.81, 4142.73, 5416.38, 3688.17,
    ],
    clicks: [
      290, 311, 297, 325, 303, 315, 295, 359, 346, 284, 348, 240, 247, 256, 265,
      275, 295, 280, 310, 327, 353, 233, 281, 95,
    ],
    cpc: [
      0.67, 0.51, 0.57, 0.56, 0.53, 0.51, 0.52, 0.61, 0.51, 0.55, 0.47, 0.46,
      0.59, 0.54, 0.51, 0.47, 0.62, 0.51, 0.54, 0.46, 0.45, 0.73, 0.58, 0.81,
    ],
    revenue_perclick: [
      18.19, 73.45, 23.49, 22.71, 31.03, 11.66, 41.41, 23.62, 25.1, 32.01,
      52.65, 26.66, 31.3, 46.92, 22.05, 27.74, 25.32, 38.63, 42.93, 22.68, 8.16,
      17.78, 19.28, 38.82,
    ],
    bookingcount: [
      16, 20, 16, 16, 14, 8, 15, 21, 23, 17, 11, 16, 15, 17, 8, 12, 12, 15, 8,
      7, 9, 7, 8, 6,
    ],
    conversion: [
      5.52, 6.43, 5.39, 4.92, 4.62, 2.54, 5.08, 5.85, 6.65, 5.99, 3.16, 6.67,
      6.07, 6.64, 3.02, 4.36, 4.07, 5.36, 2.58, 2.14, 2.55, 3, 2.85, 6.32,
    ],
    roi: [
      27.3, 143.9, 40.9, 40.3, 58.2, 23, 79.5, 39, 49.2, 57.7, 112.1, 57.4,
      52.8, 86.3, 42.9, 58.5, 40.8, 75.9, 79.6, 49.1, 18.3, 24.4, 33, 48.1,
    ],
  },
  monthly: {
    spent: [3771.25],
    generated: [207098.23],
    clicks: [6930],
    cpc: [0.54],
    revenue_perclick: [29.88],
    bookingcount: [317],
    conversion: [4.57],
    roi: [54.9],
  },
  weekly: {
    spent: [1180.77, 1111.97, 1067.78, 410.73],
    generated: [67768.23, 70719.08, 55363.64, 13247.28],
    clicks: [2136, 2080, 2105, 609],
    cpc: [0.55, 0.53, 0.51, 0.67],
    revenue_perclick: [31.73, 34, 26.3, 21.75],
    bookingcount: [105, 120, 71, 21],
    conversion: [4.92, 5.77, 3.37, 3.45],
    roi: [57.4, 63.6, 51.8, 32.3],
  },
  quarterly: {
    spent: [3771.25],
    generated: [207098.23],
    clicks: [6930],
    cpc: [0.54],
    revenue_perclick: [29.88],
    bookingcount: [317],
    conversion: [4.57],
    roi: [54.9],
  },
};

export const comparitionData = {
  daily: {
    spent: [
      198.76, 165.32, 178.49, 190.68, 155.94, 167.13, 160.2, 223.45, 182.07,
      162.91, 158.72, 119.55, 152.31, 145.7, 129.85, 137.09, 190.58, 148.92,
      162.77, 155.02, 159.34, 175.21, 167.48, 82.6,
    ],
    generated: [
      5356.72, 23114.33, 6852.34, 7459.12, 9318.45, 3795.22, 12456.48, 8563.23,
      8822.1, 9160.21, 17990.11, 6589.72, 7880.55, 12355.12, 5932.45, 7745.91,
      7524.89, 11032.44, 13456.78, 7501.9, 2903.78, 4172.95, 5380.12, 3717.19,
    ],
    clicks: [
      299, 317, 308, 332, 298, 307, 300, 367, 355, 290, 340, 250, 256, 262, 273,
      280, 298, 288, 315, 333, 360, 240, 285, 100,
    ],
    cpc: [
      0.66, 0.52, 0.58, 0.57, 0.52, 0.54, 0.53, 0.61, 0.51, 0.56, 0.46, 0.48,
      0.6, 0.55, 0.49, 0.45, 0.63, 0.5, 0.55, 0.47, 0.46, 0.72, 0.59, 0.8,
    ],
    revenue_perclick: [
      19.05, 70.12, 22.97, 23.12, 32.45, 12.25, 42.1, 22.95, 26.15, 31.78,
      50.75, 28.5, 32.15, 47.8, 21.6, 28.1, 24.05, 39.4, 43.5, 21.0, 9.0, 18.2,
      20.05, 37.6,
    ],
    bookingcount: [
      17, 18, 18, 17, 15, 10, 14, 23, 22, 18, 12, 14, 16, 18, 10, 13, 13, 14, 9,
      8, 10, 8, 9, 5,
    ],
    conversion: [
      5.6, 6.2, 5.45, 5.0, 4.7, 2.6, 5.2, 5.9, 6.75, 6.1, 3.3, 6.5, 6.1, 6.8,
      3.1, 4.4, 4.2, 5.4, 2.6, 2.2, 2.6, 3.1, 2.9, 6.4,
    ],
    roi: [
      28.0, 148.0, 42.5, 41.0, 60.0, 24.0, 81.5, 40.5, 50.0, 59.0, 115.0, 58.0,
      54.0, 88.0, 44.0, 59.0, 42.0, 77.0, 80.0, 50.0, 19.0, 25.0, 35.0, 49.0,
    ],
  },
  monthly: {
    spent: [3825.34],
    generated: [209765.12],
    clicks: [6985],
    cpc: [0.55],
    revenue_perclick: [30.12],
    bookingcount: [322],
    conversion: [4.62],
    roi: [56.0],
  },
  weekly: {
    spent: [1200.58, 1150.12, 1075.63, 425.78],
    generated: [68222.55, 71045.12, 56425.67, 13650.78],
    clicks: [2150, 2105, 2120, 630],
    cpc: [0.56, 0.54, 0.52, 0.68],
    revenue_perclick: [32.2, 33.5, 27.0, 22.0],
    bookingcount: [108, 122, 75, 22],
    conversion: [5.0, 5.8, 3.4, 3.5],
    roi: [58.0, 65.0, 53.0, 33.0],
  },
  quarterly: {
    spent: [3825.34],
    generated: [209765.12],
    clicks: [6985],
    cpc: [0.55],
    revenue_perclick: [30.12],
    bookingcount: [322],
    conversion: [4.62],
    roi: [56.0],
  },
};
