import React, {useState} from "react";
import PrimarySearchAppBar from "../../components/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import {
    homepageMenuItems, homepageSideMyMenuItems, homepageSideMoreMenuItems,
} from "../../constants/productmenu";
import {Box} from "@mui/material";

const DefaultLayout = ({page, children}) => {
    const [currentSection, setCurrentSection] = useState(page);

    return (<>
            <PrimarySearchAppBar/>
            <Box sx={{display: "flex"}}>
                <Sidebar
                    menuItems={homepageMenuItems}
                    moreItems={homepageSideMoreMenuItems}
                    initialItems={homepageSideMyMenuItems}
                    currentSection={currentSection}
                    setCurrentSection={setCurrentSection}
                />
                <Box
                    sx={{
                        backgroundColor: "#EBEBEB", flex: 1, paddingTop: 2, paddingInline: 2,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>);
};
export default DefaultLayout;
