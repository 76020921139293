export const sampleAxisData = {
  daily: [
    " 1st July 2024",
    "2nd July 2024",
    "3rd July 2024",
    "4th July 2024",
    "5th July 2024",
    "6th July 2024",
    "7th July 2024",
    " 8th July 2024",
    "9th July 2024",
    "10th July 2024",
    "11th July 2024",
    "12th July 2024",
    "13th July 2024",
    "14th July 2024",
    " 15th July 2024",
    "16th July 2024",
    "17th July 2024",
    "18th July 2024",
    "19th July 2024",
    "20th July 2024",
    "21st July 2024",
    " 22nd July 2024",
    "23rd July 2024",
    "24th July 2024",
  ],
  monthly: ["July 2024"],
  weekly: ["W27 2024", "W28 2024", "W29 2024", "W30 2024"],
  quarterly: ["Q3 2024"],
};
