import React from 'react';
import {Button, Grid, TextField, InputLabel} from '@mui/material';

const ResetPasswordForm = ({email, setEmail, handleSubmit}) => {

    return (<form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <InputLabel htmlFor="email" shrink>
                    Email
                </InputLabel>
                <TextField
                    id="email"
                    variant="outlined"
                    required
                    value={email}
                    autoComplete='off'
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    InputProps={{
                        sx: {
                            height: '30px',
                            width: '298px',
                            backgroundColor: 'transparent',
                            color: 'var(--Grey, #373F41)',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                        },
                    }}
                    InputLabelProps={{
                        shrink: true, sx: {
                            color: "373F41",
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{
                        display: "flex",
                        width: "154px",
                        height: "32px",
                        flexDirection: "column",
                        justifyContent: "center",
                        flexShrink: "0",
                        textAlign: "center",
                        fontFamily: "Quincy CF",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        whiteSpace: 'nowrap',
                        background: "#61D5A9"

                    }}
                >
                    Request password
                </Button>
            </Grid>
        </Grid>
    </form>);
};

export default ResetPasswordForm;
