import React, { useState } from "react";
import PrimarySearchAppBar from "../../components/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import {
  homepageMenuItems,
  homepageSideMyMenuItems,
  homepageSideMoreMenuItems,
} from "../../constants/productmenu";
import { DatePicker } from "../../components/DatePicker";
import { Box, Divider, Typography } from "@mui/material";
import "./homepage.scss";
import { DecreaseIcon, IncreaseIcon } from "../../assets/images/Icons";
import ChartContainer from "../../features/homepage/chartContainer";

const Homepage = () => {
  const [open, setOpen] = useState(false);
  const [currentSection] = useState(null);
  const metrics = [
    {
      label: "Cost",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
    },
    {
      label: "Generated",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
    },
    {
      label: "Clicks",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
    },
    {
      label: "CPC",
      value: "27.1$",
      percentage: "1.1",
      direction: "down",
      extra: "",
    },
    {
      label: "Conversions",
      value: "120",
      percentage: "4.1",
      direction: "up",
      extra: "",
    },
    {
      label: "ROAS",
      value: "27.1",
      percentage: "5.2",
      direction: "up",
      extra: "",
    },
  ];
  const compareWith =
    sessionStorage.getItem("currentCompareSelection") ?? "Previous period";
  return (
    <>
      <PrimarySearchAppBar />

      <Box sx={{ display: "flex" }}>
        <Sidebar
          menuItems={homepageMenuItems}
          moreItems={homepageSideMoreMenuItems}
          initialItems={homepageSideMyMenuItems}
          currentSection={currentSection}
          setCurrentSection={() => null}
        />
        <Box
          sx={{
            backgroundColor: "#EBEBEB",
            flex: 1,
            paddingTop: 2,
            paddingInline: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className="quency-cf-normal heading">
              {" "}
              Digital Marketing Platform{" "}
            </Typography>
            <DatePicker />
          </Box>
          <Box sx={{ display: "flex", marginTop: 2 }}>
            {metrics.map((item, i) => {
              return (
                <Box
                  sx={{
                    marginRight: i === metrics.length - 1 ? "0px" : "115px",
                  }}
                >
                  <Typography className="score-title">
                    {" "}
                    {item.label}{" "}
                  </Typography>
                  <Typography className="score-value">
                    {" "}
                    {item.value}{" "}
                  </Typography>
                  <Box>
                    {" "}
                    <span className="score-direction">
                      {item?.direction === "up" ? (
                        <IncreaseIcon />
                      ) : (
                        <DecreaseIcon />
                      )}{" "}
                    </span>{" "}
                    <span
                      className={
                        item?.direction === "up"
                          ? "score-percentage score-increase"
                          : "score-percentage score-decrease"
                      }
                    >
                      {" "}
                      {item.percentage}%{" "}
                    </span>
                    {i === 0 && (
                      <span style={{ fontSize: "8px" }}>
                        {" "}
                        vs {compareWith}{" "}
                      </span>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Divider
            sx={{ marginTop: 2, color: "#BAB7B7" }}
            className="divider"
          />

          <Box>
            <ChartContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Homepage;
