import {
  Checkbox,
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const CustomCheckbox = ({
  title,
  checked,
  onChange,
  size = 10,
  fontSize = 12,
}) => {
  return (
    <FormGroup>
      {" "}
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{
              paddingBlock: "5px",
              color: "#61D5A9",
              "&.Mui-checked": {
                color: "#61D5A9",
              },
            }}
            onChange={onChange}
            checked={checked}
            title={title}
          />
        }
        sx={{ "& .MuiFormControlLabel-label": { fontSize: 12 } }}
        label={title}
      />{" "}
    </FormGroup>
  );
};
export default CustomCheckbox;
